<template>
  <div id="opencourseDetail">
    <div class="tip">
      <div class="tu1" v-if="dateList.status == 2">
        <img src="~@ass/img/222222ico_wctb.png" alt="" />
      </div>
      <div class="tu1" v-if="dateList.status == 1">
        <img src="~@ass/img/ico_cw4444.png" alt="" />
      </div>
      <div class="zi1" v-if="dateList.status == 1">未支付</div>
      <div class="zi1" v-if="dateList.status == 2">支付成功</div>
    </div>
    <div class="tu2">
      <img :src="dateList.open_class_cover_img" alt="" />
    </div>
    <div class="kuai">
      <div class="zi2">
        {{ dateList.open_class_name }}
      </div>
      <div class="zi3">
        <span>订单编号：{{ dateList.number }}</span>
        <span>付款用户：{{ dateList.uname }}</span>
      </div>
    </div>
    <div class="clear"></div>
    <div class="zi4">
      时间：
      {{ dateList.order_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
    </div>
    <div class="zi5">单位：元</div>
    <div class="clear"></div>

    <table>
      <tr class="first">
        <td :class="dateList.installment_type > 0 ? 'w25' : 'w50'">订单金额</td>
        <template v-if="dateList.installment_type > 0">
          <td class="w25">分期</td>
          <td class="w25">利息</td>
        </template>
        <td class="w25" :class="dateList.installment_type > 0 ? 'w25' : 'w50'">
          实付金额
        </td>
      </tr>
      <tr>
        <td class="zi6">
          {{ dateList.selling_price }}
        </td>
        <template v-if="dateList.installment_type > 0">
          <td class="zi6">
            {{ dateList.installment_type | getInstallmentTypeText }}期
          </td>
          <td class="zi6">￥{{ dateList.interest }}元</td>
        </template>
        <td class="zi7">
          <span
            v-if="dateList.couponList && dateList.couponList.length > 0"
            class="mr10"
          >
            使用了优惠券
          </span>
          {{ dateList.price }}

          <el-popover
            placement="bottom"
            width="250"
            trigger="hover"
            style="margin-left:3px"
            v-if="dateList.couponList && dateList.couponList.length > 0"
          >
            <template slot="reference">
              <div class="vm help" :class="icon2"></div>
            </template>
            <el-table
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              :data="dateList.couponList"
            >
              <el-table-column label="优惠券名称" prop="name"></el-table-column>
              <el-table-column
                label="抵扣金额(元)"
                prop="discount_amount"
              ></el-table-column>
            </el-table>
          </el-popover>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'opencourseDetail',
  data() {
    return {
      dateList: [],
      icon2: '',
    }
  },
  created() {
    this.getDate()
  },
  filters: {
    getInstallmentTypeText(type) {
      let text
      switch (Number(type)) {
        case 1:
          text = 3
          break
        case 2:
          text = 6
          break
        case 3:
          text = 12
          break
      }
      return text
    },
  },
  methods: {
    getDate() {
      this.$http({
        url: '/openClass/orderDetails',
        data: {
          open_class_order_id: this.$route.params.id,
        },
        callback: ({ data, code }) => {
          if (code == 200) {
            this.$nextTick(() => {
              this.dateList = data
            })
          }
        },
        error: error => {
          if (error.code == 204) {
            this.dateList = []
          }
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#opencourseDetail {
  width: 100%;
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 1);
  ::v-deep .el-table__header-wrapper {
    line-height: 10px;
    height: 45px;
  }
  ::v-deep .el-table__cell {
    padding: 7px 0 !important;
  }
  .help {
    width: 14px;
    height: 14px;
    cursor: pointer;
    background: url('~@ass/img/1.2.9/ico_wh@2x.png');
    background-size: 100%;
    &.icon2 {
      width: 8px;
      height: 13px;
      background: url('~@ass/img/1.2.9/ico_xljt.png');
      background-size: 100%;
    }
  }

  .tip {
    padding-top: 20px;
    width: 100%;
    box-sizing: border-box;
    height: 80px;
    background: rgba(244, 245, 250, 1);
    .tu1 {
      width: 38px;
      height: 38px;
      float: left;
      margin-left: 20px;
    }
    .zi1 {
      width: 64px;
      height: 21px;
      font-size: 16px;
      float: left;
      margin-top: 7px;
      margin-left: 20px;
      color: rgba(51, 51, 51, 1);
      line-height: 21px;
    }
  }
  .tu2 {
    width: 143px;
    height: 80px;
    margin-top: 30px;
    float: left;
    background: rgba(110, 110, 110, 1);
    border-radius: 7px;
  }
  .tu2 img {
    width: 143px;
    height: 80px;
  }
  .kuai {
    width: calc(100% - 163px);
    float: left;
    margin-left: 20px;
    .zi3 {
      font-size: 12px;
      color: rgba(153, 153, 153, 1);
      line-height: 16px;
      margin-top: 24px;
    }
    .zi2 {
      margin-top: 30px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 14px;
      color: rgba(51, 51, 51, 1);
      line-height: 19px;
    }
    .zi3 span:first-child {
      margin-bottom: 6px;
      display: block;
    }
  }
  .clear {
    clear: both;
  }
  .zi4 {
    width: 141px;
    height: 16px;
    font-size: 12px;
    color: rgba(153, 153, 153, 1);
    line-height: 16px;
    margin-top: 30px;
    float: left;
  }
  .zi5 {
    width: 48px;
    height: 16px;
    font-size: 12px;
    color: rgba(153, 153, 153, 1);
    line-height: 16px;
    float: right;
    margin-left: 950px;
    margin-top: 30px;
  }
  table {
    width: 100%;
    height: 145px;
    border-collapse: collapse;
    margin-top: 10px;
  }
  table tr {
    height: 48px;
  }
  table tr td {
    border: 1px solid rgba(227, 227, 227, 1);
    text-align: right;
    padding: 19px;
    box-sizing: border-box;
  }
  .first {
    background: rgba(244, 245, 250, 1);
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
    line-height: 19px;
  }
  .zi6 {
    font-size: 16px;
    color: rgba(51, 51, 51, 1);
    line-height: 21px;
  }
  .zi7 {
    font-size: 16px;
    color: rgba(255, 53, 53, 1);
    line-height: 21px;
    width: 600px;
  }
  .zi7 span {
    font-size: 12px;
  }
  .w25 {
    width: 25%;
  }
  .w50 {
    width: 50%;
  }
}
</style>
